<template>
  <div class="container_detail">
    <div class="header_Css">
      <div @click="toBack"><img src="../assets/back.png" alt=""></div>
      <h2>累计充值领取</h2>
      <span>Histórico</span>
    </div>
    <div style="height: 10px;"></div>
<!--    <div class="qr-info-box1">-->
<!--      <div style="margin-left: 5px;">Pessoas de nível inferior eficazes <span style="color:#feb705;cursor: pointer;"-->
<!--          @click="show = true">{{ boxList.userCount }}</span> pessoas <span style="color:#feb705;cursor: pointer;"-->
<!--          @click="show = true">Detalhes</span>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="part_three">-->
<!--      <p>Qual é o número de jogadores efetivamente promovidos? (cumprir todas as condições indicadas abaixo)</p>-->
<!--      &lt;!&ndash; <div class="part_three_1">-->
<!--                <span>Este subordinado recarrega pela primeira vez</span><span style="color: #fff;">10 Ou o acima-->
<!--                    mencionado</span>-->
<!--            </div> &ndash;&gt;-->
<!--      <div class="part_three_1">-->
<!--        <span>O subordinado acumulou recargas</span><span style="color: #fff;">{{ boxList.rechargeSum }} Ou o acima-->
<!--          mencionado</span>-->
<!--      </div>-->
<!--      <div class="part_three_1" style="background: #000;">-->
<!--        <span>O subordinado acumulou apostas</span><span style="color: #fff;">{{ boxList.pickGamecoin }} Ou o acima-->
<!--          mencionado</span>-->
<!--      </div>-->
<!--      &lt;!&ndash; <div class="part_three_1" style="background: #000;">-->
<!--                <span>O subordinado acumulou o nº de depósitos</span><span style="color: #fff;">1 Vezes ou mais</span>-->
<!--            </div> &ndash;&gt;-->
<!--    </div>-->
    <div class="part_four">
      <block v-if="boxList.id" v-for="(item, index) in totalLine" :key="index">
        <p v-if="index" class="display: block; width: 100%; height: 25px; !important"></p>
        <div class="part_four_0" :data="item">
          <div class="part_four_1" style="flex-direction: column;">
            <div style="display: flex;" :style="{'flex-direction':(index+1)% 2 != 0? 'row':'row-reverse'}">
              <div class="d_flex" v-for="(item1, index1) in boxList.itemList"
                v-if="index1 >= (item - 1) * 4 && index1 < (item) * 4"
                :style="{'flex-direction':(index+1)% 2 != 0? 'row':'row-reverse'}">
                <div class="part_four_1_1" @click="openBox(item1)">
                  <div>
<!--                    <img v-if="item1.status == null" src="../assets/open1.png" alt="">-->
                    <img v-if="item1.userActivityStatus == 0" src="../assets/open2.png" alt="">
                    <img v-else-if="item1.userActivityStatus == 1" src="../assets/open3.png" alt="">
                    <img v-else src="../assets/open1.png" alt="">
                    <div v-if="item1.recommendNum < 10"
                      style="font-size: 10px; width: 100%;color: #fff; margin: -20px 0 0 8px;">
                      {{ item1.recommendNum }} pessoas
                    </div>
                    <div v-else
                      style="position: relative; font-size: 10px; width: 50px;text-align: center; color: #fff; margin: -20px 0 0 8px;">
                      <div style="position: absolute; top: -6px;width: 100%; color: #fff; text-align: center;">
                        {{ item1.recommendNum }}
                      </div>
                      <!-- <span v-if="item1.recommendNum > 999">
                        <br />
                      </span> -->
                      <div style="position: absolute; top: 3px; width: 100%; text-align: center; color: #fff;"> pessoas
                      </div>
                    </div>
                  </div>
                  <span :style="item1.recommendNum > 9 ? 'margin-top: 20px;' : 'margin-top: 5px;'">{{
                    item1.rewardAmount
                    }}</span>
                </div>
                <div v-if="(index1+1)% 4 != 0 " class="part_four_1_2">
                  <div v-if="(index+1)% 2 != 0 ">
                    <img style="transform: rotate(-90deg) scale(0.9);" src="../assets/down.png">
                  </div>
                  <div v-if="(index+1)% 2 == 0 ">
                    <img style="transform: rotate(-90deg) scale(0.9)" src="../assets/down.png">
                  </div>
                </div>
                <div v-if="(index1 + 1) % 4 === 0"
                  style="position: absolute; top: 80px; right: -21px; width: 100%; display: flex;">
                  <!-- :style="(index + 1) % 4 === 0 ? 'justify-content: flex-end; padding-right: 22px;' : 'justify-content:
                  flex-start; padding-left: 22px;'" -->
                  <img v-if="item != totalLine" src="../assets/down.png" alt="">
                </div>
              </div>
            </div>
            <!-- <div v-if="item ==1 || item == 3" class="toRight"> -->
            <!-- <div style="display: flex;width: 100%;" :style="{'flex-direction':(index+1)% 2 != 0? 'row':'row-reverse'}">
              <div style="flex: 1;"></div>
              <img style="padding: 0 10px;" :style="index % 4 === 0 ? 'margin-right: 10px;' : 'margin-left: 10px;'"
                v-if="item !=totalLine" src="../assets/down.png" alt="">
            </div> -->
            <!-- </div> -->
            <!-- <div v-else-if="item ==2 || item == 4" class="toLeft">
                          <img src="../assets/down.png" alt="">
                      </div> -->
          </div>
        </div>
      </block>
    </div>
<!--    <div class="part_five">-->
<!--      <span>I.Condições do Evento:</span>-->
<!--      <div>-->
<!--        <span>Somente o subordinado recém -registrado, os subordinados atendem aos requisitos de-->
<!--          atividade</span>-->
<!--      </div>-->
<!--      <span>II.Instruções Do Evento:</span>-->
<!--      <div>1.Desenvolva amigos para abrir baús de tesouro. Se você completar diferentes números de amigos, poderá-->
<!--        obter baús de tesouro correspondentes com uma quantidade máxima de 15555. Quanto mais você desenvolver,-->
<!--        maior será a recompensa;<br>2.Esta atividade é um presente adicional da plataforma e você pode desfrutar-->
<!--        de outras recompensas de agentes e comissões ao mesmo tempo, ou seja, desfrutar diretamente de múltiplas-->
<!--        alegrias; <br>3.A recompensa é limitada à coleta manual no final doAPP/iOS、APP/Android、PC/Windows, e o-->
<!--        expirado será distribuído automaticamente;<br>4.O bônus (excluindo o principal) deste evento requer 1-->
<!--        apostas válidas para ser sacado, e as apostas não são limitadas a plataforma do jogo; <br>5.Este evento-->
<!--        é limitado a operações normais realizadas pelo titular da conta. É proibido alugar, usar plug-ins-->
<!--        externos, robôs, apostar em contas diferentes, brushing mútuo, arbitragem, interface, protocolo,-->
<!--        exploração de vulnerabilidades, controle de grupo ou outros meios técnicos para participar. Caso-->
<!--        contrário, as recompensas serão canceladas ou deduzidas, a conta será congelada ou até mesmo adicionada-->
<!--        à lista negra; <br>6.Para evitar diferenças na compreensão do texto, a plataforma reserva-se o direito-->
<!--        de interpretação final deste evento. </div>-->
<!--    </div>-->
<!--    <div class="qqq">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动01.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动02.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动03.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动04.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动05.png" alt="">-->
<!--      <img class="bom_img" src="@/assets/images/home/活动06.png" alt="">-->
<!--    </div>-->
    <van-popup v-model="show">
      <div class="popupCSS">
        <h2>Link de Convite</h2>
        <div class="p1">
          <!-- <van-dropdown-menu>
                        <van-dropdown-item v-model="value1" :options="option1" />
                    </van-dropdown-menu> -->
          <div class="p1_1">Válido ou não <img src="../assets/singldown.png" alt=""></div>
          <div class="p1_2"><input type="text" style="width: 100px;background: #000;border:none" placeholder="Conta">
            <img src="../assets/search.png" alt="">
          </div>
        </div>
        <div class="nothing_css">
          <img style="width: 110px;" src="../assets/nothing.png" alt="">
          <div>Sem Registros</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {activityDetail, boxReward, receiveAccumulatedRechargeReward} from '@/api/index'
import DropdownMenu from "@/components/DropdownMenu.vue";
import VueQr from "vue-qr";

export default {
  components: {
    DropdownMenu,
    VueQr,
  },
    data() {
        return {
            show: false,
            timerId:null,
            value1: 0,
            value2: 'a',
            option1: [
                { text: 'Válido ou não', value: 0 },
                { text: 'Eficiente', value: 1 },
                { text: 'Inválido', value: 2 }
            ],
            boxList: {
            },
            totalLine: 0,
          active: 0,
          domain: '',
          option: [
            {
              label: '',
              value: 0,
            },
          ],
          value: 0,
        }
    },
  created() {
    console.log(2222, window.location.origin)
    this.option[0].label = `${window.location.origin}?code=${this.$store.state.userInfo.inviteCode }`
  },
    mounted() {
        this.getActivityDetail()
    },
    beforeDestroy() {
        // 在组件销毁前清除定时器
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    },
    methods: {
        handleCopy(e) {
          console.log(e)
        },
        getTextWidth(text, font) {
          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          context.font = font || '16px Arial'
          // 测量文本宽度
          const metrics = context.measureText(text)
          return metrics.width
        },
        tabChanged(e) {
          this.tabStyle = {
            '--lineWidth': this.getTextWidth(this.tabs[e], '16px') + 10 + 'px',
          }
        },
        onClickLeft() {
          this.$router.go(-1)
        },
        toNewPage() {
          window.open(this.option[0].label)
        },
        getActivityDetail() {
            activityDetail(
                this.$route.query.id
            ).then(res => {
                this.boxList = res.data
                if (this.boxList) {
                    let plus = this.boxList.itemList.length % 4;

                    this.totalLine = parseInt(this.boxList.itemList.length / 4);
                    if (plus > 0) {
                        this.totalLine = this.totalLine + 1;
                    }
                    console.log(this.totalLine)
                }
            })
        },
        openBox(item1){
            console.log(item1)
            if(item1.userActivityStatus=="-1" || !item1.userActivityStatus){
                this.$toast.fail("Não atende às condições e não pode ser ativado")
            }else if(item1.userActivityStatus=="0"){
              receiveAccumulatedRechargeReward(item1.userActivityId).then(res=>{
                  if (res.code==200){
                    this.$toast.success("Recebido com sucesso")
                    this.getActivityDetail();
                  }else {
                    this.$toast.fail("Falha ao coletar")
                  }
                })
            }else{
                this.$toast.fail("Você já está ativado")
            }
        },
        toBack() {
            this.$router.push('/home/event')
        }
    }
}
</script>

<style lang="scss" scoped>
.container_detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #303030;
}

.header_Css {
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    width: 100%;

    img {
        cursor: pointer;
    }

    h2 {
        color: #fff;
        font-size: 15px;
        width: 260px;
        text-align: center;
    }

    span {
        color: #feaf75;
        cursor: pointer;
    }
}

.d_flex {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.part_two {
    width: 390px;
    height: 305px;
    background: #000000;
    border-radius: 5px;

    .ptwo_div_1 {
        height: 30px;
        padding: 5px;
        border-bottom: 1px solid;
        margin: 0 10px;

        span {
            color: #fff;
        }
    }

    .ptwo_div_2 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 5px;
        margin: 10px 0;

        .ptwo_div_2_1 {
            font-size: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 5px;
            background: #fff;
            height: 100px;
            margin-right: 3px;

            img {
                width: 70px;
                padding: 2px;
            }

            span {
                color: #000;
                background: #feb705;
                font-size: 10px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        .ptwo_div_2_2 {
            display: flex;
            flex-direction: column;
            height: 90px;
            justify-content: space-between;

            .ptwo_div_2_2_1 {
                display: flex;
                justify-content: space-between;
                /* padding: 0 10px; */
            }

            .ptwo_div_2_2_2 {
                border: 1px solid #ccc;
                /* margin: 0 10px; */
                height: 60px;
                border-radius: 5px;

                span {
                    text-align: justify;
                    font-size: 12px;
                }
            }

        }
    }

    .ptwo_div_3 {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        .ptwo_div_3_1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            img {
                width: 38px;
                height: 38px;
            }
        }
    }

}

.part_three {
    //width: 391px;
    /* height: 223px; */
    border-radius: 5px;
    background: #000;
    margin: 10px 0;
    text-align: center;

    p {
        font-size: 12px;
        padding: 5px;
    }

    .part_three_1 {
        background: #303030;
        margin: 10px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 11px;
            width: 150px;
        }
    }
}

.part_four {
  padding: 0 10px;
    // margin: 0 10px;
    //padding-left: 15px;
    height: 100%;
    background: #000;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 5px;
    .part_four_0 {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .part_four_1 {
            display: flex;
            align-items: center;

            .part_four_1_1 {
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                align-items: center !important;

                img {
                    width: 65px !important;
                    height: 60px !important;
                }

                span {
                    color: #fff;
                }
            }

            .part_four_1_2 {
                margin: 0 10px;
            }
        }
    }

    .toLeft {
        width: 100%;
        padding-left: 20px;
        display: flex;
        justify-content: flex-start;
        margin: 0 20px;
    }

    .toRight {
        width: 100%;
        padding-right: 20px;
        display: flex;
        justify-content: flex-end;
        margin: 0 20px;
    }

}

.part_five {
    //width: 391px;
    background: #000;
    border-radius: 5px;
    margin: 10px 0;
    padding-top: 10px;
    padding-left: 10px;

}

.popupCSS {
    width: 330px;
    height: 675px;
    background: #000;

    h2 {
        color: #fff;
        text-align: center;
    }

    .p1 {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;

        .p1_1 {
            width: 120px;
            height: 30px;
            border: 1px solid #ccc;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .p1_2 {
            border-radius: 20px;
            border: 1px solid #ccc;
            width: 160px;
            display: flex;
            justify-content: center
        }
    }

    .nothing_css {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 200px;
    }
}

.qqq {
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    display: flex;

    .bom_img {
        width: 104px;
        height: 50px;
        margin: 0 5px
    }
}
.qr-info-box1 {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 0.06rem;
  background: #000;

  .qr-title {
    color: #f1f1f1;
    font-size: 0.16rem;
    padding: 0.1rem;
    height: 0.52rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #555;
  }

  .qr-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.1rem;

    .qr-body-left {
      width: 1.2rem;

      .qr {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 1.06rem;
        background: #f1f1f1;
        border-radius: 0.08rem;

        img {
          width: 0.9rem;
          margin: 0.1rem;
        }

        .qr-info {
          color: #1c1c1c;
          background: #f7b500;
          font-size: 0.14rem;
          border-bottom-left-radius: 0.08rem;
          border-bottom-right-radius: 0.08rem;
          text-align: center;
        }
      }
    }

    .qr-body-right {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .qr-body-right-top {
        display: flex;
        margin-top: 0.04rem;
        justify-content: space-between;
        align-items: center;

        span {
          color: #777777;
          font-size: 0.14rem;
        }

        .share {
          width: 0.2rem;
        }
      }

      .qr-body-right-bottom {
        margin-top: 0.1rem;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.qr-info-box {
  width: 92%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 0.06rem;
  background: #1c1c1c;

  .qr-title {
    color: #f1f1f1;
    font-size: 0.16rem;
    padding: 0.1rem;
    height: 0.52rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #555;
  }

  .qr-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.1rem;

    .qr-body-left {
      width: 1.2rem;

      .qr {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 1.06rem;
        background: #f1f1f1;
        border-radius: 0.08rem;

        img {
          width: 0.9rem;
          margin: 0.1rem;
        }

        .qr-info {
          color: #1c1c1c;
          background: #f7b500;
          font-size: 0.14rem;
          border-bottom-left-radius: 0.08rem;
          border-bottom-right-radius: 0.08rem;
          text-align: center;
        }
      }
    }

    .qr-body-right {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .qr-body-right-top {
        display: flex;
        margin-top: 0.04rem;
        justify-content: space-between;
        align-items: center;

        span {
          color: #777777;
          font-size: 0.14rem;
        }

        .share {
          width: 0.2rem;
        }
      }

      .qr-body-right-bottom {
        margin-top: 0.1rem;
      }
    }
  }
}
.top-box {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.2rem 0 0.2rem;
  background: #1c1c1c;
  border-radius: 0.1rem;
  margin: 0.2rem;
  position: relative;

  .top-box-left {
    width: 1rem;
    position: relative;
  }

  .img {
    position: absolute;
    width: 0.9rem;
    top: 0;
  }

  .top-box-center {
    width: 60%;

    .top-box-center-top {
      color: #767474;
      font-size: 0.15rem;
    }

    .top-box-center-bottom {
      color: #f7b500;
      font-size: 0.16rem;
      display: flex;
      align-items: flex-start;
      height: 0.2rem;

      span {
        margin-right: 0.1rem;
      }

      img {
        width: 0.19rem;
      }
    }

    .topp-box-Id {
      font-size: 0.16rem;
      margin-top: 0.14rem;

      span {
        font-size: 0.15rem;
      }

      span:nth-child(2) {
        padding-left: 0.08rem;
      }
    }
  }

  .top-box-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    button {
      background: #f7b500;
      color: #333;
      font-size: 0.12rem;
      border-radius: 0.06rem;
      padding: 0.05rem 0.1rem;
      margin-bottom: 0.1rem;
      outline: none;
      border: none;

      &:nth-child(2) {
        background: #999999;
        color: #f1f1f1;
      }
    }

    p {
      font-size: 0.19rem;
      color: #f7b500;
    }
  }
}

.softwares{
  display: flex;
  justify-content: space-around;
  .soft-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    img{
      width: 40px;
    }
  }
}
</style>
